#home {
  background-color: #24305e;
  text-align: center;
  padding: 100px 40px 40px 40px;  
}

.home {
  display: flex;
  align-items: center;
  justify-content: space-evenly;
}

.home__avatar {
  border-radius: 50%;
  border: 2px solid var(--color-white);
}

.home__introduction {
  display: inline;
}

.home__name {
  color: var(--color-highlight);
}

.home__title,
.home__description {
  color: var(--color-white);
}


.home__contact {
  border-radius: var(--size-border-radius);
  border: 2px solid var(--color-white);
  color: var(--color-white);
  font-size: var(--font-small);
  font-weight: var(--weight-semi-bold);
  padding: 8px 12px;
  margin-top: 24px;
}

/* .home__contact:hover {
  background-color: var(--color-dark-pink);
} */

.home__contact:hover {
  font-size: var(--font-regular);
  transition: var(--transition-duration);
}

@media screen and (max-width: 768px) {
  .home {
    flex-direction: column;
    align-items: center;
    justify-content: center;
  }

  .home__avatar {
    text-align: center;
  }

  .home__introduction {
    display: block;
  }
}