#skills{
  background-color: var(--color-light-white);
  padding: 60px 40px 40px 40px;
}

.skill__title,
.skill__subtitle,
.skill__description {
  color: var(--color-dark-grey);
}

.skill__title{
  margin: 0;
}

.skillset {
  margin-top: 20px;
  color: var(--color-light-white);
  background-color: #DAE8F9;
  padding: 20px;
}

.skillset img {
  height: 75px;
  width: 75px;
  margin: 30px;
}

.skillset__item {
  display: flex;
  align-items: center;
  margin: 0 10px;
  /* justify-content: space-between; */
}


.skillset__title {
  font-weight: var(--weight-bold);
  color: var(--color-light-white);
}

@media screen and (max-width: 768px) {
  .skillset {
    flex-direction: column;
  }
}