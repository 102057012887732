#navbar {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: var(--color-white);
  background-color: transparent;
  position: fixed;
  padding: 0 16px;
  width: 100%;
  z-index: 1;
  transition: all var(--transition-duration) ease-in-out;
}

#navbar.navbar__dark {
  background-color: var(--color-pink);
  height: 62px;
}

.navbar__logo {
  margin-top: 7px;
}

.navbar__logo__icon {
  width: 30px;
  height: 30px;
  line-height: 30px;
  opacity: 1;
  cursor: pointer;
  border-radius: var(--size-border-radius);
}

.navbar__logo__icon:hover {
  width: 35px;
  height: 35px;
  opacity: 1;
  transition: var(--transition-duration);
}

.navbar__menu {
  display: flex;
  justify-content: space-between;
  transition: all var(--transition-duration) ease;
}

.navbar__menu__item {
  margin: 0 4px;
  padding: 8px 12px;
  cursor: pointer;
  color: var(--color-white);
  font-weight: var(--weight-semi-bold);
  font-size: var(--font-small);
  border-radius: var(--size-border-radius);
  border: 2px solid transparent;
  transition: all var(--transition-duration) ease-in-out;
}

.navbar__menu__item:hover {
  background-color: var(--color-dark-pink);
  border-color: var(--color-white);
  color: var(--color-pink);
}

.navbar__toggle-btn {
  position: absolute;
  right: 19px;
  top: 16px;
  display: none;
  padding: 0;
}

.navbar__toggle-btn:hover svg, path {
  cursor: pointer;
}


@media screen and (max-width: 768px){
  .navbar__toggle-btn{
    display: block;
  }
  
  .navbar__logo__icon {
    margin: 10px 14px;
  }

  #navbar {
    flex-direction: column;
    align-items: flex-start;
    padding: 0;
  }
  
  .navbar__menu {
    flex-direction: column;
    text-align: center;
    width: 100%;
    background-color: var(--color-pink);
    opacity: 1;
  }
  
  .navbar__menu.expanded {
    display: none;
  }

}
