#contact {
  background-color: var(--color-pink);
}

.contact__title {
  padding: 30px;
  color: var(--color-white);
}

.contact__email {
  margin: 20px;
  color: var(--color-white);
}

.contact__links {
  padding: 20px;
  color: var(--color-light-white);
  font-size: var(--font-large);
  margin: 10px;
  display: flex;
  justify-content: center;
}

.contact__icons {
  color: var(--color-light-white);
  font-size: var(--font-large);
  margin: 15px;
  transition: all var(--transition-duration) ease;

}

.contact__icon svg {
  cursor: pointer;
}

.contact__icon svg path{
  cursor: pointer;
}

.contact__icon svg:hover {
  transform: scale(1.2);
  color: var(--color-dark-pink);
}