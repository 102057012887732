#mywork {
  padding-top: 40px;
}

.mywork__title {
  margin-top: 30px;
}

.mywork__buttons {
  margin-top: 30px;
}

.mywork__button {
  padding: 5px 20px;
  border: 2px solid var(--color-light-grey);
  border-radius: var(--size-border-radius);
  margin: 3px;
  font-weight: var(--weight-semi-bold);
}

.mywork__button.active,
.mywork__button:hover {
  background-color: var(--color-dark-pink);
  color: var(--color-pink);
  border: 2px solid var(--color-dark-pink);
}

.mywork__button.active .mywork__count,
.mywork__button:hover .mywork__count {
  opacity: 1;
  top: 0;
}

.mywork__count {
  margin-left: 5px;
  background-color: var(--color-pink);
  width: 24px;
  height: 24px;
  line-height: 24px;
  display: inline-block;
  border-radius: 50%;
  color: var(--color-dark-pink);
  position: relative;
  top: -20px;
  left: 5px;
  opacity: 0;
  transition: all var(--transition-duration) ease-in;
}


.projects {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.project {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 30px;
  width: 350px;
  height: 250px;
}

.project__img {
  max-width: 100%;
  max-height: 100%;
}

.project__description {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  opacity: 0;
  background-color: black;
  display: flex;
  flex-direction: column;
  justify-content: center;
  transform: translateY(-10px);
  transition: all var(--transition-duration) ease-in;
}

.project:hover .project__description {
  opacity: 0.8;
  cursor: pointer;
  transform: translateY(0);
}

.project:hover .project__description h3,
.project:hover .project__description span {
  cursor: pointer;
}

.project__description h3,
.project__description span{
  color: var(--color-white);  
}

.project__description h3 {
  font-size: var(--font-regular);
  font-weight: var(--weight-semi-bold);
}

.project__description span {
  font-size: var(--font-micro);
}

@media screen and (max-width: 768px) {
  .project {
    flex-grow: 1;
  }
}