:root {
    /* Color */
    --color-white: #ffffff;
    --color-light-white: #eeeeee;
    --color-dark-white: #bdbdbd;
    --color-pink: #374785;
    --color-dark-pink: #a8d0e6;
    --color-dark-grey: #4d4d4d;
    --color-grey: #616161;
    --color-light-grey: #7c7979;
    --color-blue: #73aace;
    --color-yellow: #fff7d1;
    --color-orange: #feb546;
    --color-black: #000000;
    --color-highlight: #f76c6c;
    
    /* Font size */
    --font-large: 48px;
    --font-medium: 28px;
    --font-regular: 18px;
    --font-small: 16px;
    --font-micro: 14px;
  
    /* Font weight */
    --weight-bold: 700;
    --weight-semi-bold: 600;
    --weight-regular: 400;

    /* Size */
    --size-border-radius: 4px;

    /* Animation */
    --transition-duration: 300ms;
}

/* Universal tags */
* {
  font-family: 'Open Sans', sans-serif;
  box-sizing: border-box;
  cursor: default;
}

a {
  text-decoration: none;
}

ul {
  list-style: none;
  padding-left: 0;
}

button {
  cursor: pointer;
  background-color: transparent;
  border: none;
  outline: none;
}

/* Typography */

h1 {
  font-size: var(--font-large);
  font-weight: var(--weight-bold);
  color: var(--color-black);
  margin: 16px 0px;
}

h2 {
  font-size: var(--font-medium);
  font-weight: var(--weight-semi-bold);
  color: var(--color-black);
  margin: 8px 0;
}

h3 {
  font-size: var(--font-regular);
  font-weight: var(--weight-regular);
  color: var(--color-black);
  margin: 8px 0;
}

p {
  font-size: var(--font-regular);
  font-weight: var(--weight-regular);
  color: var(--color-black);
  margin: 4px 0;
}

.section {
  text-align: center;
}

.section__container {
  max-width: 1000;
  margin: 50px;
}