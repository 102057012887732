#about {
  padding-top: 40px;
}

.about__title,
.about__description {
  color: var(--color-dark-grey);
}

.about__majors {
  display: flex;
  justify-content: space-evenly;
  margin: 60px 0;
}

.major__icon {
  border: 2px solid var(--color-pink);
  padding: 40px;
  border-radius: 50%;
}

.major__icon:hover {
  transform: rotate(15deg);
  transition: all var(--transition-duration) ease;
}

.major__title,
.major__description {
  color: var(--color-dark-grey);

}

.major__description {
  font-size: var(--font-small);
}

.about__description__detail {
  color: var(--color-dark-grey);
  margin: 15px;
}

@media screen and (max-width: 768px){
  .about__majors{
    flex-direction: column;
  }

  .major__front-end {
    margin-bottom: 30px;
  }

}